import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/linkbutton"
import { StaticImage } from "gatsby-plugin-image"
import "../stylesheets/business.scss"

const BusinessPage = () => (
  <Layout>
    <SEO
      title="事業内容"
      description="道新インタラクティブ株式会社の事業内容です。主に北海道に特化した求人サービスを運営し、地域に信頼される人材プラットフォームを構築することを目指しています。"
    />
    <div className="businessPage-MainVisual">
      <div className="businessPage-MainVisual-headingWrapper">
        <h1 className="businessPage-MainVisual-heading">事業内容</h1>
        <p className="businessPage-MainVisual-subHeading">Business</p>
      </div>
    </div>
    <section className="businessPage-ServiceSection">
      <div className="businessPage-ServiceSection-inner">
        <div className="businessPage-ServiceSection-titleWrapper">
          <h2 className="businessPage-ServiceSection-heading">
            <a
              href="https://hokkaido.jobantenna.jp/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/jobantenna.png"
                alt="JobAntenna 北海道"
                className="businessPage-ServiceSection-image"
              />
            </a>
          </h2>
          <p className="businessPage-ServiceSection-subHeading">
            いいかも！で企業とつながる。
            <br />
            北海道に特化した求人マッチングサービス
          </p>
          <div className="businessPage-ServiceSection-buttonWrapper">
            <Button
              to="https://hokkaido.jobantenna.jp/"
              target="_blank"
              className="Button Button--openInNew"
            >
              転職をお考えの方はこちら
            </Button>
          </div>
          <div className="businessPage-ServiceSection-buttonWrapper">
            <Button
              to="https://hokkaido.jobantenna.jp/partners/contact/"
              target="_blank"
              className="Button--primary Button--openInNew-primary"
            >
              採用をお考えの方はこちら
            </Button>
          </div>
        </div>
        <div className="businessPage-ServiceSection-descriptionWrapper">
          <div className="businessPage-ServiceSection-descriptionItem">
            <h3 className="businessPage-ServiceSection-descriptionHeading">
              即戦力人材の獲得
            </h3>
            <p className="businessPage-ServiceSection-descriptionText">
              資格保有者・即戦力人材は、応募を待つよりスカウトが有効です。
              <br />
              応募を待つ採用だけではなく、スカウト機能を利用することでダイレクトリクルーティングが可能になります。
            </p>
          </div>
          <div className="businessPage-ServiceSection-descriptionItem">
            <h3 className="businessPage-ServiceSection-descriptionHeading">
              採用ミスマッチの大幅軽減
            </h3>
            <p className="businessPage-ServiceSection-descriptionText">
              ジョブアンテナでは給料や条件面以上に大切にしているものがあります。それは組織が掲げるビジョンや想いを表現し「共感」を軸に採用する方法です。
              <br />
              プロライターによる求人取材、フォトグラファーによる撮影で、「企業の想い」を採用力に変えています。
            </p>
          </div>
          <div className="businessPage-ServiceSection-descriptionItem">
            <h3 className="businessPage-ServiceSection-descriptionHeading">
              ぴったりな人材をじっくり探せます
            </h3>
            <p className="businessPage-ServiceSection-descriptionText">
              中途採用では候補者と接点をもってから入社までに３ヶ月程度かかります。
              <br />
              そのためジョブアンテナでは長期掲載にも適した料金プランをご用意してます。
              <br />
              採用時の成果報酬もかからないため、少ない固定費で、じっくりと、ぴったりな人材を何人でも採用できます。
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default BusinessPage
